<template>
  <div class="container-fluid pb-3" style="margin-top:15px">
  <vue-progress-bar></vue-progress-bar>
  <template v-if="knowledgeBaseList"> <!-- && this.granted_knowledge_base == 1 -->
    <div class="row">
      <div class="col-lg-12">
        <ul class="list-group">
        <template v-for="item in knowledgeBaseList">
          <li :key="item.uuid" class="list-group-item list-group-item--shadow flex-column align-items-start pt-4">
            <div class="d-flex w-100 justify-content-between">
              <h4>
                <router-link class="text-primary" :to='showKnowledgeBaseItem(item.uuid)'>{{ item.title }}</router-link>
              </h4>
            </div>
            <div class="text-muted">
              <hr>
              <small class="mr-3"><i class="fe fe-calendar mr-2"></i>{{ item.createdAt.substr(0, 10) }}</small>
              <small class="mr-3"><i class="fe fe-layers mr-2"></i>{{ item.category.title }}</small>
              <template v-if="item.tags">
                <small class="mr-3"><i class="fe fe-tag mr-1"></i>
                  <template v-for="tag in item.tags.split(',')">
                    {{ tag }}
                  </template>
                </small>
              </template>
              <hr>
            </div>
            <template v-if="item.photo">
            <img :src='`${dataBaseUrl}/media/knowledge_base/${item.photo}`' alt="photo" class="lead-photo img-fluid float-left mr-3" />
            </template>
            <p class="mb-3">{{ item.description }}</p>
            <p class="text-right">
              <router-link tag='span' :to='showKnowledgeBaseItem(item.uuid)' style='cursor:pointer;' class="btn btn-primary btn-sm"><i class="fe fe-chevron-right mr-2"></i>Więcej</router-link>
            </p>
          </li>
        </template>
        </ul>
      </div><!-- /col -->
    </div><!-- /row -->
  </template>
  <template v-else>
    <div v-if="hasError" class="alert alert-warning alert--grouped">
      <i class="fe fe-alert-triangle mr-2"></i>
      Uwaga!<br/>Zasób nie istnieje lub nie masz do niego dostępu.
    </div>
    <div v-else class="loader"></div>
  </template>
  <template>
    <div v-if="totalItems == 0" class="alert alert-info alert--grouped mt-3">
      <i class="fe fe-alert-triangle mr-2"></i>
      Brak elementów do wyświetlenia.
    </div>
  </template>
  </div><!-- /container-fluid -->
</template>

<script>
import { baseUrl } from '@/axios-config';
import { mapState } from 'vuex'
export default {
  data: function() {
    return {
      // granted_knowledge_base: 0,
      hasError: false,
      dataBaseUrl: baseUrl,
    };
  },
  created: function() {
    // const token = localStorage.getItem('token');
    // const decoded = token !== null ? jwtDecode(token) : null;

    // if (decoded !== null) {
    //   if (decoded.roles.includes('ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW')) {
    //     this.granted_knowledge_base = 1;
    //   } else {
    //     this.granted_knowledge_base = 0;
    //   }
    // } else {
    //   this.$router.push('login');
    // }
    this.getDataOnCreation();
  },
  computed: {
    ...mapState('knowledgeBase', ['knowledgeBaseList', 'totalItems']),
    allowToShow: function() {
      return this.granted_knowledge_base === 1;
    },
  },
  methods: {
    getDataOnCreation: function() {
      this.$Progress.start();
      this.$store.dispatch('knowledgeBase/setKnowledgeBaseList')
      .then(()=>{
        this.$Progress.finish();
      })
      .catch(error=>{
        this.$Progress.fail();
        this.hasError = true;
        console.error('Error:', error);
      })
    },
    showKnowledgeBaseItem(id) {
      return `/knowledge_base/show/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/scoped-forms";

.lead-photo {
  max-height: 100px;
}
</style>
